<template>
  <div ref="container">
    <v-menu
      ref="menu"
      v-model="menu"
      transition="scale-transition"
      offset-y
      min-width="auto"
      :close-on-content-click="false"
      :return-value.sync="date"
      :nudge-top="-10"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :value="dateFormatted"
          append-icon="mdi-calendar"
          :label="label"
          readonly
          outlined
          dense
          hide-details
          v-on="on"
          v-bind="attrs"
          @keydown.up="up"
          @keydown.down="down"
          @keydown.left="left"
          @keydown.right="right"
          @keydown.enter="saveDate"
          @focus="$emit('focus')"
        ></v-text-field>
      </template>

      <v-date-picker
        ref="datePicker"
        first-day-of-week="1"
        dense
        v-model="date"
        :value="dateFormatted"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="hideMenu">
          {{ $translations.cancel_label }}
        </v-btn>
        <v-btn text color="primary" @click="saveDate">
          {{ $translations.save_label }}
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      date: null,
      menu: false,
    };
  },
  mounted() {
    if (this.value) {
      const self = this;
      setTimeout(() => {
        self.date = self.value;
      }, 100);
    }
  },
  computed: {
    dateFormatted() {
      return this.date ? moment(this.date).format("DD-MM-YYYY") : "";
    },
  },
  watch: {
    value() {
      this.date = this.value;
    },
  },
  methods: {
    saveDate() {
      this.$refs.menu.save(this.date);
      this.hideMenu();
    },
    hideMenu() {
      this.menu = false;
      this.$emit("closeDatePicker", this.date);
    },
    // keyboard navigation
    pickModified(modifier) {
      this.$refs.datePicker.dateClick(
        modifier(moment(this.$refs.datePicker.inputDate)).format("YYYY-MM-DD")
      );
    },
    up() {
      this.pickModified((m) => m.subtract(1, "weeks"));
    },
    down() {
      this.pickModified((m) => m.add(1, "weeks"));
    },
    left(e) {
      this.pickModified((m) =>
        e.shiftKey ? m.subtract(1, "month") : m.subtract(1, "days")
      );
    },
    right(e) {
      this.pickModified((m) =>
        e.shiftKey ? m.add(1, "month") : m.add(1, "days")
      );
    },
  },
};
</script>
<template>
  <v-app>
    <loadingScreen :isLoading="loading" />

    <v-app-bar
      id="app-bar"
      app
      role="banner"
      flat
      :dense="$vuetify.breakpoint.mobile"
      class="sticky"
    >
      <dw-header
        v-if="$vuetify.breakpoint.mdAndUp"
        :drawer="drawer"
        :userName="userName"
        @updateDrawer="($event) => (drawer = $event)"
        :section="section"
      />

      <div class="h-75" v-else>
        <a :href="clientUrl" :aria-label="$translations.client_logo_link_label">
          <v-img
            :alt="$translations.client_logo_label"
            :src="baseUrl + logo"
          ></v-img>
        </a>
      </div>

      <template v-if="$vuetify.breakpoint.smAndDown" v-slot:extension>
        <dw-header
          :drawer="drawer"
          @updateDrawer="($event) => (drawer = $event)"
          :section="section"
          :userName="userName"
        />
      </template>
    </v-app-bar>

    <v-navigation-drawer
      v-if="this.$route.name && !this.$route.name.includes('Auth')"
      app
      id="navigation-drawer"
      :value="drawerModel"
      :mini-variant.sync="miniDrawer"
      v-click-outside="hideDrawer"
      :touchless="true"
    >
      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp"
        class="absolute-menu-opener"
        rounded
        :title="
          drawer
            ? $translations.open_menu_label
            : $translations.close_menu_label
        "
        :aria-label="
          drawer
            ? $translations.open_menu_label
            : $translations.close_menu_label
        "
        icon
        small
        @click="drawer = !drawer"
        color="primary"
      >
        <v-icon
          color="primary"
          :class="!drawer ? 'rotated-180' : ''"
          aria-hidden="true"
          >mdi-chevron-right</v-icon
        >
      </v-btn>

      <div class="pa-4 pb-0" v-if="(!miniDrawer || !drawerModel) && isLoggedIn">
        <v-select
          v-model="section"
          class="mb-2"
          dense
          outlined
          :label="$translations.section_label"
          hide-details
          :items="sections"
        ></v-select>
      </div>

      <v-list nav dense>
        <v-list-item
          exact
          color="primary"
          :to="{ name: section + '.Dashboard' }"
          :aria-label="$translations.homepage_label"
        >
          <v-list-item-icon>
            <v-icon aria-hidden="true">mdi-home</v-icon>
            <span class="d-sr-only">{{ $translations.homepage_label }}</span>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $translations.homepage_label
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>

      <div :class="drawer ? '' : 'mb-6'" v-if="section != null">
        <v-list nav dense v-for="(section, i) in datalist[section]" :key="i">
          <v-divider v-if="i != 0" :class="drawer ? 'mb-4' : ''"></v-divider>
          <v-subheader v-if="!drawer && section && 'label' in section">{{
            section.label
          }}</v-subheader>
          <v-list-item-group v-model="selectedItem" color="primary" role="none">
            <v-list-item
              v-for="(tile, k) in section.entries"
              :key="k"
              :to="{ name: tile.name }"
            >
              <v-list-item-icon>
                <v-icon v-if="tile.icon.includes('mdi')"
                  :title="tile.label"
                  aria-haspopup="false"
                  aria-hidden="true"
                >{{ tile.icon }}</v-icon>
                <v-icon
                  v-else
                  class="dwi-small"
                  :title="tile.label"
                  aria-haspopup="false"
                  aria-hidden="true"
                  >dwi-{{ tile.icon }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">{{
                  tile.label
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-navigation-drawer>

    <v-main id="main" class="fill-height background">
      <v-card flat class="fill-height pb-13" rounded="0">
        <transition name="component-fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-card>

      <v-footer :padless="true">
        <v-card flat tile width="100%" class="grey lighten-5 text-center">
          <v-card-text>
            <v-row justify="space-between">
              <div>
                <v-btn
                  v-if="footer.sitemap == '1'"
                  class="mx-4"
                  text
                  :to="{ name: 'General.Sitemap' }"
                  color="primary"
                >
                  {{ $translations.siteMapLabel }}
                </v-btn>
              </div>
              <div>
                <div class="mb-2">
                  <a
                    v-if="footer.footerTitle1 && footer.footerLink1"
                    class="mx-4"
                    :href="footer.footerLink1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ footer.footerTitle1 }}
                  </a>
                  <span v-else-if="footer.footerTitle1">{{
                    footer.footerTitle1
                  }}</span>

                  <a
                    v-if="footer.footerTitle2 && footer.footerLink2"
                    class="mx-4"
                    :href="footer.footerLink2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ footer.footerTitle2 }}
                  </a>
                  <span v-else-if="footer.footerTitle2">{{
                    footer.footerTitle2
                  }}</span>
                </div>
                <div>
                  <a
                    v-if="footer.footerTitle3 && footer.footerLink3"
                    class="mx-4"
                    :href="footer.footerLink3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ footer.footerTitle3 }}
                  </a>
                  <span v-else-if="footer.footerTitle3">{{
                    footer.footerTitle3
                  }}</span>
                  <a
                    v-if="footer.footerTitle4 && footer.footerLink4"
                    class="mx-4"
                    :href="footer.footerLink4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ footer.footerTitle4 }}
                  </a>
                  <span v-else-if="footer.footerTitle4">{{
                    footer.footerTitle4
                  }}</span>
                </div>
              </div>
              <div>
                <span class="my-auto mr-3">
                  V{{ currentVersion }}/{{ new Date().getFullYear() }} —
                  <strong>{{ clientName }}</strong>
                </span>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </v-main>

    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      small
      color="primary"
      class="mb-10"
      @click="toTop"
      :class="chatAvailable ? 'btn-to-top' : ''"
      :title="$translations.aria_labels.scroll_to_top_label"
      :aria-label="$translations.aria_labels.scroll_to_top_label"
    >
      <v-icon aria-hidden="true">mdi-chevron-up</v-icon>
    </v-btn>

    <v-btn
      right
      bottom
      fixed
      v-model="isChatOpened"
      color="primary"
      class="mb-10"
      dark
      fab
      small
      :aria-label="$translations.aria_labels.chat_label"
      @click="isChatOpened = !isChatOpened"
      v-if="chatAvailable"
    >
      <v-icon v-if="isChatOpened" aria-hidden="true"> mdi-close </v-icon>
      <v-icon class="chat-wiggle" v-else aria-hidden="true"> mdi-chat </v-icon>
    </v-btn>
    <v-card
      v-if="isChatOpened"
      elevation="5"
      class="chat-card"
      :height="chatError ? '' : '750px'"
      width="500px"
    >
      <v-alert
        v-if="chatError"
        class="ma-4"
        :color="darkModeTextColor('red')"
        type="error"
        dense
        text
        >{{ chatError }}</v-alert
      >
      <v-row
        v-if="chatLoading"
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col class="text-subtitle-1 text-center" cols="12">
          {{ $translations.chatLoading }}
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <iframe
        id="rc"
        v-else-if="!chatError"
        :src="chatData.link"
        height="750px"
        width="500px"
        frameborder="0"
      ></iframe>
    </v-card>
  </v-app>
</template>

<script>
import generalService from "./axios/general";
import generalChatService from "./axios/general/chat";
import loadingScreen from "./views/general/loading";

export default {
  data() {
    return {
      drawer: false,
      datalist: [],

      loading: false,
      error: null,
      selectedItem: 0,

      section: null,

      isChatOpened: false,
      chatLoading: false,
      chatData: {},
      chatError: null,
      helpTag: null,

      languagesModel: false,
      customerLinksModel: false,
      fab: false,
    };
  },
  components: {
    loadingScreen,
  },
  created() {
    this.loading = true;
    this.$store.dispatch("fetchGeneral").then(() => {
      this.loading = false;
      document.title = this.clientName;
    });

    this.enableSearch = this.$vuetify.breakpoint.mdAndUp;
  },
  mounted() {
    this.getHelpTag();

    this.tablist.forEach((item) => {
      this.datalist[item.section] = [];
      this.fetchMenu(item);
    });
  },
  computed: {
    currentVersion() {
      try {
        return require("./version.txt").default;
      } catch (error) {
        return "unkown";
      }
    },
    urlSection() {
      return this.$route.query.section;
    },
    drawerModel() {
      return this.$vuetify.breakpoint.lgAndUp ? true : this.drawer;
    },
    miniDrawer() {
      return this.$vuetify.breakpoint.mdAndDown ? false : this.drawer;
    },
    sections() {
      return this.tablist.map((item) => ({
        text: item.label,
        value: item.section,
      }));
    },
    baseUrl() {
      return process.env.NODE_ENV == "development"
        ? process.env.VUE_APP_BASEURL
        : window.location.origin;
    },
    isLoggedIn() {
      return this.$store.getters.getSID != null;
    },
    clientName() {
      return this.$store.getters.getClient;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    tablist() {
      return this.$store.getters.getTabs;
    },
    footer() {
      return this.$store.getters.getFooter;
    },
    translations() {
      return this.$store.getters.getTranslations;
    },
    logo() {
      return this.$store.getters.getLogo;
    },
    chatAvailable() {
      return this.$store.getters.getChatAvailable;
    },
    defaultSection() {
      return this.$store.getters.getDefaultSection;
    },
    drawerDefaultOpen() {
      return this.$store.getters.getNavbarDefaultOpen != 1;
    },
    clientUrl() {
      return this.$store.getters.getClientUrl;
    },
    publicAllowed() {
      return this.$store.getters.getPublicAllowed;
    },
  },

  watch: {
    isLoggedIn(isLoggedIn) {
      this.fetchGeneral();

      if (!isLoggedIn) {
        if (this.publicAllowed) {
          this.section = "Public";
        } else {
          this.section = "Council";
          this.$router.push({ name: "Auth.Login" });
        }
      }
    },
    tablist(tablist) {
      tablist.forEach((item) => {
        this.fetchMenu(item);
      });
    },
    sections(newList) {
      if (!this.section) {
        if (!this.isLoggedIn && this.publicAllowed) {
          this.section = "Public";
        } else if (this.defaultSection) {
          this.section = this.defaultSection;
        } else if (newList.length > 0) {
          this.section = newList[0].value;
        }
      }
    },
    isChatOpened() {
      if (Object.keys(this.chatData).length == 0) {
        this.fetchChatData();
      }
    },
    defaultSection() {
      if (this.defaultSection) {
        this.section = this.defaultSection;
      }
    },
    $route(newValue) {
      // reset the drawer after navigation if the drawer can be hidden under the hamburger icon.
      if (this.$vuetify.breakpoint.lgAndDown) {
        this.drawer = false;
      }

      if (
        !this.section &&
        newValue &&
        newValue.name &&
        !newValue.name.includes("Auth") &&
        !newValue.name.includes("General")
      ) {
        if (this.isLoggedIn) {
          this.section = newValue.name.split(".")[0];
        } else {
          if (this.publicAllowed) {
            this.section = "Public";
          } else {
            if (this.defaultSection) {
              this.section = this.defaultSection;
            } else {
              this.section = "Council";
            }
          }
        }
        // this.section = this.isLoggedIn
        // ? newValue.name.split(".")[0]
        // : (this.publicAllowed ? "Public" : "Council");
      }

      if (this.section) {
        this.setSection();
      }
      this.getHelpTag();
    },
    section() {
      const section = this.tablist.find((tab) => tab.section === this.section);
      if (section && section.link) {
        window.location.href = section.link;
      }

      const route = this.$route;
      if (route.name) {
        if (route.name.includes("Login") || route.name.includes("NotFound")) {
          return false;
        }

        if (!this.publicAllowed) {
          if (!this.section) {
            if (this.defaultSection) {
              this.section = this.defaultSection;
            } else {
              this.section = "Council";
            }
          } else if (this.section == "Public" || route.name.includes("Public")) {
            this.section = this.defaultSection;
          }
        }

        let routerName = route.name.split(".");
        routerName.shift();
        const link = this.$router.resolve({
          name: [this.section, ...routerName].join("."),
        });
        // if module doesn't exist in the switched to section go to the homepage
        // if (link.route.matched.length) {
        if (this.section == route.name.split(".")[0]) {
          // Todo@Rubin ONLY RUN THIS code when not yet on the route
          this.$router
            .push({
              ...this.$route,
              name: link.route.name,
            })
            .catch((e) => console.error(e));
        } else if (
          route.name != "Auth.SSO" &&
          route.name != "Auth.ResetPassword" &&
          route.name != "General.Preferences" &&
          route.name != "General.Sitemap"
        ) {
          this.$router.push({
            ...this.$route,
            name: this.section + ".Dashboard",
          });
        }
      }
    },
    urlSection() {
      if (this.urlSection) {
        this.section = this.urlSection;

        this.$router.replace({
          query: {},
        });
      }
    },
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    setSection() {
      this.$global.section = this.section;
      this.$global.moduleSection = this.$global.section.toLowerCase();
    },
    getHelpTag() {
      if (this.$route.matched.some((m) => "helpTag" in m.meta)) {
        this.helpTag =
          this.$route.matched.find((m) => m.meta.helpTag).meta.helpTag || null;
      }
    },
    hideDrawer(e) {
      if (e.target.className === "v-overlay__scrim") {
        this.drawer = false;
      }
    },
    fetchGeneral() {
      this.loading = true;
      this.$store.dispatch("fetchGeneral").then(() => {
        this.loading = false;
      });
    },
    fetchMenu(item) {
      // no item or not for vue
      if (!item || !item.section || item.link) {
        return false;
      }

      this.loading = true;
      generalService
        .getMenuTab(`vji/${item.section.toLowerCase()}/menu`)
        .then((res) => {
          this.datalist[item.section] = res.filter(
            (subItems) => subItems.entries.length > 0
          );

          this.datalist[item.section].forEach((subList) => {
            if ("entries" in subList && subList.entries.length > 0) {
              subList.entries.forEach((menuItem) => {
                menuItem.name =
                  item.section +
                  "." +
                  menuItem.name.charAt(0).toUpperCase() +
                  menuItem.name.slice(1);
              });
            }
          });

          // Don't remove this weird code. solves the bug in vm-3802
          if (!this.drawerDefaultOpen) {
            this.drawer = false;
            if (this.$vuetify.breakpoint.mdAndUp) {
              this.drawer = true;
            }
          } else {
            this.drawer = true;
            if (this.$vuetify.breakpoint.mdAndUp) {
              this.drawer = false;
            }
          }
          // until here
        })
        .catch((err) => {
          this.error = err;
        })
        .then(() => {
          this.loading = false;
        });
    },
    fetchChatData() {
      this.chatLoading = true;
      generalChatService
        .doLoadChat()
        .then((response) => {
          this.chatData = response;
          setTimeout(() => {
            document.getElementById("rc").contentWindow.postMessage(
              {
                externalCommand: "logout",
              },
              "*"
            );
          }, 1000);

          setTimeout(() => {
            document.getElementById("rc").contentWindow.postMessage(
              {
                externalCommand: "login-with-token",
                token: this.chatData.token,
              },
              "*"
            );
            document.getElementById("rc").contentWindow.postMessage(
              {
                externalCommand: "go",
                path: "/channel/general",
              },
              "*"
            );
          }, 4000);
        })
        .catch((error) => {
          this.chatError = error;
        })
        .then(() => {
          this.chatLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans"),
    url(./fonts/OpenSans/OpenSans-Regular.ttf) format("truetype");
}
$bs-body-font-family: "OpenSans-Regular";
#app,
.v-application .text-h1,
.v-application .text-h2,
.v-application .text-h3,
.v-application .text-h4,
.v-application .text-h5,
.v-application .text-h6,
.v-application .text-subtitle-1,
.v-application .text-subtitle-2,
.v-application .text-body-1,
.v-application .text-body-2,
.v-application .text-button,
.v-application .text-caption,
.v-application .text-overline {
  font-family: "OpenSans-Regular", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v-select__selections {
  line-height: 19px;
}

.v-application .theme--light.v-btn.v-btn--disabled {
  color: #616161 !important;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button.v-expansion-panel-header {
  cursor: auto !important;
}

.v-expansion-panel-header__icon i {
  cursor: pointer;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.sticky {
  position: fixed !important;
  top: 0;
  z-index: 100;
}

.dwBreadcrumb {
  background-color: #fff;
  color: #333;
  width: 100%;
  border-radius: 5px;
}

.skip-link {
  background-color: #fff;
  font-weight: 700;
  left: 20%;
  padding: 4px;
  position: absolute;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.3s;
}

.skip-link:focus {
  opacity: 1;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.5s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}

// .v-list-item__icon {
//   margin-right: 15px !important;
// }

i.dwi.dwi-small {
  font-size: 24px !important;
}

.v-item--active .v-list-item__title {
  font-weight: 700 !important;
}

.v-navigation-drawer *::-webkit-scrollbar {
  width: 10px;
}

.float-right-bottom {
  margin: 20px;
  align-self: end;

  .v-speed-dial__list {
    right: 0 !important;
    width: auto !important;
    left: auto !important;
  }
}

.chat-card {
  z-index: 12;

  bottom: 86px;
  right: 16px;
  position: fixed !important;
}

.absolute-menu-opener {
  position: absolute;
  top: 10px;
  right: -15px;
  z-index: 10;
  border: 1px solid #e0e0e0;
  background-color: #fff;
}

.v-navigation-drawer {
  overflow: visible !important;
}

.mdi-cog {
  transition: 0.4s;
}

.mdi-cog:hover {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.rotated-180 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.chat-wiggle:hover {
  animation-name: wiggle;
  -webkit-animation-name: wiggle;
  -ms-animation-name: wiggle;

  animation-duration: 1000ms;
  -ms-animation-duration: 1000ms;
  -webkit-animation-duration: 1000ms;

  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;

  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  -ms-animation-timing-function: ease-in-out;
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(20deg);
  }
  75% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-ms-keyframes wiggle {
  0% {
    -ms-transform: rotate(1deg);
  }
  25% {
    -ms-transform: rotate(-1deg);
  }
  50% {
    -ms-transform: rotate(1.5deg);
  }
  75% {
    -ms-transform: rotate(-5deg);
  }
  100% {
    -ms-transform: rotate(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(20deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.v-list .v-list-item--active .v-icon.theme--dark {
  color: white !important;
}

a.v-item--active.theme--dark > .v-list-item__content > div.v-list-item__title {
  color: white !important;
}

.theme--dark .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.theme--dark.v-btn.v-btn--disabled .v-icon {
  font-size: 0.875rem;
  height: 48px;
  color: white !important;
}

.theme--light .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.theme--light.v-btn.v-btn--disabled .v-icon {
  font-size: 0.875rem;
  height: 48px;
  color: #424242 !important;
}
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 32px !important;
}

.theme--light.v-list-item.v-list-item--highlighted::before {
  opacity: 0.16 !important;
}
.v-data-table-header button:focus .v-data-table-header__icon {
  transform: none;
  opacity: 1;
}
.theme--light .v-data-table-header button:focus .v-data-table-header__icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.theme--dark .v-data-table-header button:focus .v-data-table-header__icon {
  color: white !important;
}

button.mb-10.v-btn.v-btn--bottom.v-btn--is-elevated.v-btn--fab.v-btn--fixed.v-btn--has-bg.v-btn--right.v-btn--round.theme--dark.v-size--small.primary.btn-to-top {
  right: 75px;
}
</style>

export default [
	{
		path: "agenda",
		meta: {
      helpTag: "council:agenda",
    },
    component: () =>
			import(
				"../../views/general/agenda"
			),
			children: [
				{
					path: "",
					name: "Council.Agenda",
					component: () =>
						import(
							"../../views/general/agenda/list"
						),
				},
				{
					path: ":hexkey/:ap_hexkey([a-z0-9]{32})?",
					name: "Council.Agenda.Details",
					component: () =>
						import(
							"../../views/general/agenda/agenda.vue"
						),
				},
			],
  },
  {
    path: "agenda/:hexkey",
    meta: {
      helpTag: "council:agendavideo"
    },
    component: () => import("../../views/council-public/agendavideo"),
    children: [
      {
        path: "vod/:ap_hexkey?",
        name: "Council.AgendaVideo.VOD",
        component: () =>
          import(
            "../../views/council-public/agendavideo/vod"
          ),
      },
      {
        path: "live",
        name: "Council.AgendaVideo.Live",
        component: () =>
          import(
            "../../views/council-public/agendavideo/live"
          ),
      },
    ],
  },
];
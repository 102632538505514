import axios from "axios";
import store from "../store";
import router from "../router";

/**
 * Request Wrapper with default success/error actions
 */

const request = async function (
  options,
  withCredentials = false,
  fullResponse = false
) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  headers["DWSession"] = store.getters.getSID || "";
  if (headers["DWSession"] != "") {
    if (!store.getters.getLastRequestTime) {
      store.commit("setLastRequest");
    }

    if (store.getters.isSessionActive) {
      store.commit("setLastRequest");
    } else {
      // store.commit("setSID", null);
    }
  }

  const client = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    withCredentials: withCredentials,
    headers: headers,
  });

  const onSuccess = function (response, fullResponse = false) {
    console.debug("Request Successful!", response);

    return fullResponse ? response : response.data;
  };

  const onError = function (error) {
    console.debug("Request Failed:", error.config);

    // store.dispatch("addError", error);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.debug("Status:", error.response.status);
      console.debug("Data:", error.response.data);
      console.debug("Headers:", error.response.headers);

      if (error.response.status === 401 && !error.response.request.responseURL.includes("session/action=sso")) {
        store.commit("setSID", null);
        store.commit("setUsername", null);

        router.push({
          ...router.history.current,
          name: "Auth.Login",
          params: { ...router.history.current.params, needsLogin: "1" },
        });
      } else if (error.response.status === 420) {
        router.push({
          path: error.response.data.url,
        });
      }
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.debug("Error Message:", error.message);

      store.commit('addError', error.response);
    }

    return Promise.reject(error.response || error.message);
  };

  return client.request(options).then(onSuccess, fullResponse).catch(onError);
};

export default request;

export default [
  {
    path: "agenda",
    meta: {
      helpTag: "public:agenda",
    },
    component: () => import("../../views/general/agenda"),
    children: [
      {
        path: "",
        name: "Public.Agenda",
        component: () => import("../../views/general/agenda/list.vue"),
      },
      {
        path: ":hexkey/:ap_hexkey([a-z0-9]{32})?",
        name: "Public.Agenda.Details",
        component: () => import("../../views/general/agenda/agenda.vue"),
      },
    ],
  },
  {
    path: "agenda/:hexkey",
    meta: {
      helpTag: "public:agendavideo",
    },
    component: () => import("../../views/council-public/agendavideo"),
    children: [
      {
        path: "vod/:ap_hexkey?",
        name: "Public.AgendaVideo.VOD",
        component: () => import("../../views/council-public/agendavideo/vod"),
      },
      {
        path: "live",
        name: "Public.AgendaVideo.Live",
        component: () => import("../../views/council-public/agendavideo/live"),
      },
    ],
  },
];

<template>
  <v-dialog v-model="dialog" scrollable max-width="900px" :role="dialogData.role">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on" role="menuitem">
        <v-list-item-content>
          <v-list-item-title v-text="dialogData.linkText"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>
        <v-skeleton-loader
          v-if="loading"
          type="card-heading"
          width="100%"
        ></v-skeleton-loader>
        <v-row v-else justify="space-between" class="flex-nowrap">
          <v-col>
            <h1 class="text-h5">{{ title || "NO TITLE" }}</h1>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="primary"
              :title="closeText"
              :aria-label="closeText"
              icon
              @click="dialog = false"
            >
              <v-icon aria-hidden="true">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="text-left">
        <v-skeleton-loader
          v-if="loading"
          type="list-item-two-line@5"
        ></v-skeleton-loader>
        <template v-else v-for="(detailItem, i) in detailData">
          <div v-if="detailItem.type == 'table' && detailItem.table" :key="'annex' + i">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="(header, i) in detailItem.table.header"
                      :key="'header_' + i"
                      class="text-left"
                    >
                      {{ header.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(dataItem, j) in detailItem.table.data"
                    :key="'body_' + j"
                  >
                    <td v-for="(field, k) in dataItem" :key="'field_' + k">
                      <v-icon v-if="detailItem.icon">{{
                        detailItem.icon
                      }}</v-icon>
                      <p v-if="field.link">
                        <a
                          :href="field.link"
                          target="_blank"
                          rel="noopener noreferrer"
                          :aria-label="$translations.aria_labels.to_dc_text +
                                    ' ' +
                                    field.text"
                          :title="$translations.aria_labels.to_dc_text +
                                    ' ' +
                                    field.text"
                          >{{ field.text }}</a
                        >
                      </p>
                      <p v-else>{{ field.text }}</p>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => {
    return {
      dialog: false,
      loading: false,
      error: false,
      detailData: [],
    };
  },
  props: {
    dialogData: {
      type: Object,
      default: () => {
        return {
          isListItem: false,
          linkText: null,
          linkIcon: null,
          cancelText: null,
          submitText: null,
          editText: null,
          buttonColor: null,
          buttonClass: null,
          iconColor: null,
          iconClass: null,
          role: 'dialog',
        };
      },
    },
    module: String,
    hexkey: String,
    isVisible: Boolean,
    annexLabel: String,
    item: Object,
    disabled: Boolean,
  },
  watch: {
    dialog: function (newValue) {
      if (newValue) {
        this.fetchData();
      } else {
        Object.assign(this.$data, this.$options.data());
      }

      if (this.dialog != this.isVisible) {
        this.changeShowEventDialog(newValue);
      }
    },
    isVisible: function (newValue) {
      if (this.dialog != newValue) {
        this.dialog = newValue;
      }
    },
  },
  computed: {
    title: function () {
      return this.detailData.length > 0
        ? this.detailData.find((item) => item.type === "title").text
        : null;
    },
    closeText() {
      return this.$store.getters.getTranslations.actions.close;
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const apiCall = await import(`../../../axios/${this.module}`);
      apiCall.default
        .getDataDetails({ hexkey: this.hexkey })
        .then((response) => {
          this.detailData = response.map((item) => {
            if (typeof item.text == "object") {
              item.text = item.text.join("<br>");
            }

            return item;
          });
        })
        .catch((error) => {
          this.error = error;
        })
        .then(() => (this.loading = false));
    },
    changeShowEventDialog(state) {
      this.$emit("changeDialogState", state);
    },
  },
};
</script>
<style lang="less" scoped>
.dw-docs .v-expansion-panel-header,
.v-expansion-panel-content:deep(.v-expansion-panel-content__wrap) {
  padding: 0 !important;
}
</style>
